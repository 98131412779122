import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import {getLanguage} from "../i18n/init";

const reqMoment = require.context('moment/locale', true, /\.js$/);

export default (i18nextLng) => {
  const language = getLanguage(i18nextLng) ?? {countryCode: 'pl', langCode: 'PL'}

  axios.defaults.headers.common.locale = `${language.countryCode}-${language.langCode}`
  i18n.changeLanguage(`${language.countryCode}-${language.langCode}`)

  switch (i18nextLng) {
    case 'en-GB':
    case 'en-US':
      reqMoment('./en-gb.js');
      break;
    case 'hy-AM':
      reqMoment('./hy-am.js');
      break;
    case 'no-NO':
      reqMoment('./nb.js');
      break;
    default:
      reqMoment(`./${language.countryCode}.js`);
  }
  moment.locale(i18nextLng)
}