import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import setLanguage from "../utils/setLanguage";

const reqDateFns = require.context('date-fns/locale', true, /\.js$/);

/**
 * Kod kraju - ISO 3166-1
 * Kod języka - ISO 639-1
 * Konwencja nazw: i18n/[ISO 3166-1]_[ISO 639-1].json
 */
export const LANGUAGES_LIST = []
const resources = {}
const req = require.context('.', true, /\.json$/);

req.keys().forEach((filename) => {
  const country_lang = filename.replace('./', '').replace('.json', '');
  const countryCode = country_lang.split('_')[0]?.toLowerCase();
  const langCode = country_lang.split('_')[1];

  resources[countryCode] = req(filename);
  LANGUAGES_LIST.push({
    countryCode,
    langCode,
  });
});

export const getLanguage = (lang = i18n.language) => {
  const exact = LANGUAGES_LIST.find(({countryCode, langCode}) => lang === `${countryCode}-${langCode}`)
  if(exact){
    return exact
  }

  return LANGUAGES_LIST.find(({countryCode, langCode}) => lang.startsWith(countryCode))
}

export const getLocaleDateFns = () => {
  switch (i18n.language) {
    case 'en-GB':
    case 'en-US':
      return reqDateFns(`./${i18n.language}/index.js`);
    case 'no-NO':
      return reqDateFns('./nb/index.js');
    default:
      const language = getLanguage()
      return reqDateFns(`./${language.countryCode}/index.js`);
  }
}

const isProduction = process.env.NODE_ENV === 'production'

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: isProduction ? 'pl' : undefined,
    debug: isProduction,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    saveMissing: !isProduction
  });

setLanguage(localStorage.getItem('i18nextLng'))

export default i18n;